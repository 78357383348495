<template>
  <div class="site-main">
    <div id="top-target" class="eicproduct-login__main gds-flex-container">
      <nav class="eicproduct-login__nav">
        <div class="eicproduct-login__nav__inner-link-wrap">
          <div @click="renderLoginForm" :class="!isGuest ? 'div-active eicproduct-login__nav-item eicproduct-login__nav-item--mobile-button': 'eicproduct-login__nav-item eicproduct-login__nav-item--mobile-button'">Login to submit a rebate</div>
          <div @click="renderGuestForm" :class="isGuest ? 'div-active eicproduct-login__nav-item eicproduct-login__nav-item--mobile-button': 'eicproduct-login__nav-item eicproduct-login__nav-item--mobile-button'">No account? Sign up as a guest!</div>
        </div>
      </nav>

      <!-- Login as a Guest -->
      <div v-if="isGuest" class="eicproduct-login__login-type-container">
        <section class="eicproduct-login__guest-section">
          <h2 class="eicproduct-login__main-headline gds-display-2">Submit Rebate as a guest</h2>
          <p class="gds-body-normal">
            If you have an online account, you can submit a rebate by
            <span id="submitAsGuest" @click="renderLoginForm">Logging In.</span>
          </p>
          <div v-if="errorWrongNumber" class="form-message-box form-message-box--full-width gds-space-stack-l">
            We were unable to locate an account that matched the information you provided. You can also
            <span class="gds-nobreak">
              <a
                href="https://static.greenmountainpower.com/rebate-forms/GMP-EV-Rebate-Form.pdf"
                target="_blank"
              >fill out a form here</a>.
            </span>
          </div>
          <form class="eicproduct-login__form" @submit.prevent="GuestLogin()">
            <fieldset class="gds-fieldset gds-space-stack-m" :class="{ 'gds-has-error': errorWrongNumber }">
              <label class="gds-input-field">
                <span class="gds-input-field__label">Account Number</span>
                <input
                  v-model="accountNumber"
                  v-mask="'###########'"
                  v-validate="'required|numeric|min:10|max:11'"
                  name="account number"
                  key="account number"
                  class="gds-input-field__input"
                  type="tel"
                />
                <div v-if="errors.first('account number')" class="validation-error">{{ errors.first("account number") }}</div>
              </label>
            </fieldset>

            <fieldset class="gds-fieldset gds-space-stack-m" :class="{ 'gds-has-error': errorWrongNumber }">
              <label class="gds-input-field">
                <span class="gds-input-field__label">Phone Number (Last 4 Digits)</span>
                <input v-model="phone" v-mask="'####'" v-validate="'required|numeric|min:4|max:4'" name="phone number" key="phone number" class="gds-input-field__input" type="tel" />
                <div v-if="errors.first('phone number')" class="validation-error">{{ errors.first("phone number") }}</div>
              </label>
            </fieldset>

            <div class="gds-space-stack-l">
              <div v-if="errorLogin" id="one-time-submission_error" class="gds-helper-text-error">{{ errorLogin }}</div>
            </div>

            <div class="gds-space-stack-l">
              <button :disabled="pendingLogin" :class="{ 'gds-loading': pendingLogin }" type="submit" class="gds-button">
                <span class="gds-button__text">Next</span>
              </button>
            </div>
          </form>
        </section>
      </div>

      <!-- Login as a user -->
      <div v-if="!isGuest" class="eicproduct-login__login-type-container">
        <section class="eicproduct-login__login-section">
          <h2 class="eicproduct-login__main-headline gds-display-2">Log in to Submit Rebate</h2>
          <p class="gds-body-normal">
            Log in to your online GMP account to submit a rebate. Don’t have an online account? You can
            <span id="submitAsGuest" @click="renderGuestForm">Submit as Guest.</span>
          </p>
          <form class="global-panel__form" @submit.prevent="Login()">
            <fieldset class="gds-fieldset gds-space-stack-m" :class="{ 'gds-has-error': errorlogin }">
              <label class="gds-input-field">
                <span class="gds-input-field__label">Username or Email</span>
                <input
                  v-model="username"
                  v-validate="'required'"
                  name="user name"
                  key="user name"
                  class="gds-input-field__input"
                  type="text"
                  spellcheck="false"
                  autocapitalize="none"
                  autocomplete="username"
                />
                <div v-if="errors.first('user name')" class="validation-error">{{ errors.first("user name")}}</div>
              </label>
            </fieldset>

            <fieldset class="gds-fieldset gds-space-stack-m" :class="{ 'gds-has-error': errorlogin }">
              <label class="gds-input-field">
                <span class="gds-input-field__label">Password</span>
                <widget-password-input v-model="password" autocomplete="current-password" v-validate="'required'" name="password" key="password" />
                <div v-if="errors.first('password')" class="validation-error">{{ errors.first("password")}}</div>
              </label>
            </fieldset>

            <div class="global-panel__form-helpers gds-flex-container gds-flex-container--space-between gds-space-stack-l">
              <div class="global-login-panel__form-helper">
                <label class="gds-checkbox gds-checkbox-thin">
                  <input v-model="stayloggedin" type="checkbox" id="stay-logged-in" />
                  <span class="gds-checkbox__faux"></span>
                  <span class="gds-checkbox__label gds-display-00 gds-text-grey">Stay Logged In</span>
                </label>
              </div>

              <div class="global-login-panel__form-helper">
                <a href="/account/forgot" class="gds-display-00 gds-link gds-link--bold">Forgot Password?</a>
              </div>
            </div>

            <div class="gds-space-stack-l">
              <div v-if="errorlogin" id="submission_error" class="gds-helper-text-error">{{ errorlogin }}</div>
            </div>

            <div class="gds-space-stack-l">
              <button :disabled="pendingLogin" :class="{ 'gds-loading': pendingLogin }" type="submit" class="gds-button">
                <span class="gds-button__text">Log In</span>
              </button>
            </div>
          </form>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import WidgetPasswordInput from "../WidgetPasswordInput";
import { DumpError } from "../../utilities";

export default {
  name: "EICProductsLogin",
  components: {
    WidgetPasswordInput,
  },
  data() {
    return {
      isGuest: undefined,

      username: undefined,
      password: undefined,
      stayloggedin: true,

      errorlogin: undefined,
      pendingLogin: false,
    };
  },
  computed: {
    isRestrictedRebateUser() {
      return !!this.$store.state?.user?.userinfo?.groups?.find(item => item.name === 'RESTRICTED_REBATE_USER');
    },
  },
  async mounted() {
  },
  methods: {
    renderLoginForm() {
      this.isGuest = undefined
    },
    renderGuestForm() {
      this.isGuest = true;
      this.accountNumber = "",
        this.phone = "",
        this.errorLogin = undefined,
        this.errorWrongNumber = undefined,
        this.pendingLogin = false,
        this.GMPPhoneNumber = undefined
    },
    async GuestLogin() {
      if (!await this.$validator.validateAll()) return;
      this.errorLogin = undefined;
      this.errorWrongNumber = false;
      this.pendingLogin = true;
      try {
        await this.$store.dispatch("LoginOTP", { accountNumber: this.accountNumber, phoneLast: this.phone });
        this.$router.push({ path: "/form/1" });
      } catch (err) {
        DumpError("Onetime login error", err);
        if (err.response && err.response.data && err.response.data.errorCode === "INVALID_ACCOUNT_PHONE") {
          this.errorWrongNumber = true;
        } else {
          this.errorLogin = "There was an error logging in. Please try again later.";
        }
      } finally {
        this.pendingLogin = false;
      }
    },
    async Login() {
      try {
        if (!await this.$validator.validateAll()) return;
        this.errorlogin = false;
        this.pendingLogin = true;
        await this.$store.dispatch("LoginUser", { username: this.username, password: this.password, stayloggedin: this.stayloggedin });
        if (!this.isRestrictedRebateUser) {
          this.$router.push({ path: "/account" });
        } else {
          this.$router.push({ path: "/electric-vehicle-rebate"});
        }
      } catch (err) {
        DumpError("Login error", err);
        if (err.response && err.response.data && err.response.data.errorCode === "AUTHENTICATION_FAILURE") {
          this.errorlogin = "The email or username and password provided are incorrect.";
        } else if (err.response && err.response.data && err.response.data.errorCode === "ACCOUNT_LOCKOUT_FAILURE") {
          this.errorlogin = err.response.data.message;
        } else {
          this.errorlogin = "There was an error logging in. Please try again later.";
        }
      } finally {
        this.pendingLogin = false;
      }
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>