import { FORM_TYPES } from '../../constants';
import { InitialCaps } from '../../../../../utilities';
import formConfigurations from './FormConfigurationsNew.json';
import AddDeviceFormViewModel from '../shared/AddDeviceFormViewModel';

export default class AddNewViewModel extends AddDeviceFormViewModel {
  constructor() {
    super();
    this.pageNumberLast = 5;

    Object.assign(this, formConfigurations);

    this.formData = {
      deviceType: undefined,
      installer: undefined,
      installationHasSolar: undefined,
      batteryInterests: [false, false, false],
    };

    this.pendingSave = false;
    this.installationLocation = '';
    this.selectedDeviceTypeLabel = '';
    this.primaryResidenceLocation = '';
    this.selectedDeviceTypeLabelForHeader = '';
    this.reviewAndSubmitConfig = { formData: {} };

    this.headerConfig = { ...formConfigurations.headerConfigDefault };
  }

  get isNextButtonDisabled() {
    const data = this.formData;
    let contactNameDefined = false;
    let contactPhoneDefined = false;
    let contactEmailDefined = false;
    let batteryInterestsDefined = false;

    switch (this.pageNumber) {
    case 1:
      return !AddNewViewModel.isDefined(data.installationHasSolar) || !AddNewViewModel.isDefined(data.installationLocation);
    case 2:
      return false;
    case 3:
      return !AddNewViewModel.isDefined(data.installer);
    case 4:
      contactNameDefined = AddNewViewModel.stringIsNotEmpty(data.contactName);
      contactPhoneDefined = AddNewViewModel.stringIsNotEmpty(data.contactPhone);
      contactEmailDefined = AddNewViewModel.stringIsNotEmpty(data.contactEmail);
      batteryInterestsDefined = data.batteryInterests && data.batteryInterests.length;
      return !contactNameDefined || !contactPhoneDefined || !contactEmailDefined || !batteryInterestsDefined;
    default:
      return false;
    }
  }

  updateReviewAndSubmitConfig(currentAccount) {
    this.reviewAndSubmitConfig = this.getReviewAndSubmitConfig(currentAccount);
  }

  pageNumberWatchHandler(newValue, oldValue, currentAccount) {
    if (newValue === oldValue) return;
    this.updatePageHeader();
    if (newValue === this.pageNumberLast) {
      this.updateReviewAndSubmitConfig(currentAccount);
    }
  }

  getReviewAndSubmitConfig(currentAccount) {
    return {
      formData: this.formData,
      formType: FORM_TYPES.ADD_DEVICE_NEW,
      accountNumber: currentAccount.accountNumber,
      primaryResidenceHtml: this.primaryResidenceHtml,
      reviewAndSubmitMessage: this.reviewAndSubmitMessage,
      selectedDeviceTypeLabel: this.selectedDeviceTypeLabel,
    };
  }

  setSelectedDeviceTypeLabel(deviceType) {
    const labels = {
      'ev-charger': { label: 'EV charger', header: 'an EV charger' },
      'home-battery': { label: 'home battery', header: 'a home battery' },
      default: { label: 'device', header: 'a device of your choice' },
    };
    const { label, header } = labels[deviceType] || labels.default;
    this.selectedDeviceTypeLabel = label;
    this.selectedDeviceTypeLabelForHeader = header;
  }

  formDataWatchHandler(newValue) {
    Object.keys(newValue).forEach((key) => {
      if (key === 'deviceType') {
        this.setSelectedDeviceTypeLabel(newValue[key]);
      }

      if (key === 'installationLocation') {
        if (newValue[key] === 'primary-residence') {
          this.installationLocation = this.primaryResidenceLocation;
        } else {
          this.installationLocation = this.nonPrimaryResidenceLocation;
        }
      }
    });
  }

  updatePageHeader() {
    const config = this.headerConfig;
    switch (this.pageNumber) {
    case 2:
      config.header = `Install ${this.selectedDeviceTypeLabelForHeader}`;
      config.summary = '';
      break;
    case 3:
      config.header = `Install ${this.selectedDeviceTypeLabelForHeader}`;
      config.summary = 'Please select an installer to work with for your battery installation.';
      break;
    case 4:
      config.header = `Install ${this.selectedDeviceTypeLabelForHeader}`;
      config.summary = `A representative from ${this.formData.installer} will contact you to answer questions and schedule your installation.`;
      break;
    case 5:
      config.header = 'Review and Submit';
      config.summary = `${InitialCaps(this.selectedDeviceTypeLabel)} installation request.`;
      break;
    default:
      this.headerConfig = { ...formConfigurations.headerConfigDefault };
      break;
    }
  }
}
