import { render, staticRenderFns } from "./WidgetProfileUsername.vue?vue&type=template&id=271843d0&scoped=true&"
import script from "./WidgetProfileUsername.vue?vue&type=script&lang=js&"
export * from "./WidgetProfileUsername.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "271843d0",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/bamboo-agent-home/xml-data/build-dir/WWW-WEBQA-JOB1/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('271843d0')) {
      api.createRecord('271843d0', component.options)
    } else {
      api.reload('271843d0', component.options)
    }
    module.hot.accept("./WidgetProfileUsername.vue?vue&type=template&id=271843d0&scoped=true&", function () {
      api.rerender('271843d0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/src/components/userprofile/WidgetProfileUsername.vue"
export default component.exports