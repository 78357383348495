var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.formVM.pageNumber === 1
        ? _c("BackButton", { attrs: { route: "/devices/add-device" } })
        : _vm._e(),
      _vm._v(" "),
      _vm.formVM.pageNumber > 1 && !_vm.formVM.deadEndBrandAndModel
        ? _c("BackButton", {
            attrs: { "on-link-clicked": _vm.onBackButtonClicked }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "form-container gds-space-stack-l" }, [
        _c(
          "form",
          {
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.submitForm.apply(null, arguments)
              }
            }
          },
          [
            _c("FormHeader", { attrs: { config: _vm.formVM.headerConfig } }),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.formVM.isPageOneVisible,
                    expression: "formVM.isPageOneVisible"
                  }
                ]
              },
              [
                _c("RadioGroupDeviceType", {
                  attrs: { config: _vm.formVM.radioButtonConfigDeviceType },
                  model: {
                    value: _vm.formVM.formData.deviceType,
                    callback: function($$v) {
                      _vm.$set(_vm.formVM.formData, "deviceType", $$v)
                    },
                    expression: "formVM.formData.deviceType"
                  }
                }),
                _vm._v(" "),
                _vm.formVM.formData.deviceType
                  ? _c("RadioGroupFormattedHtml", {
                      attrs: {
                        config: _vm.formVM.radioButtonConfigInstallationLocation
                      },
                      model: {
                        value: _vm.formVM.formData.installationLocation,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.formVM.formData,
                            "installationLocation",
                            $$v
                          )
                        },
                        expression: "formVM.formData.installationLocation"
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.formVM.formData.deviceType
                  ? _c("RadioGroupFormattedHtml", {
                      attrs: {
                        config: _vm.formVM.radioButtonConfigInstallationHasSolar
                      },
                      model: {
                        value: _vm.formVM.formData.installationHasSolar,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.formVM.formData,
                            "installationHasSolar",
                            $$v
                          )
                        },
                        expression: "formVM.formData.installationHasSolar"
                      }
                    })
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.formVM.isPageTwoVisible,
                    expression: "formVM.isPageTwoVisible"
                  }
                ]
              },
              [
                _c("DevicesInstallerStart", {
                  attrs: {
                    location: _vm.formVM.installationLocation,
                    "device-type": _vm.formVM.selectedDeviceTypeLabel
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.formVM.isPageThreeVisible,
                    expression: "formVM.isPageThreeVisible"
                  }
                ],
                staticClass:
                  "gds-flex-container gds-flex-container--left gds-flex-container--stretch gds-flex-container--wrap devices-row-gap"
              },
              [
                _vm.formVM.formData.deviceType
                  ? _c("RadioGroupFormattedHtml", {
                      attrs: { config: _vm.formVM.radioButtonConfigInstaller },
                      model: {
                        value: _vm.formVM.formData.installer,
                        callback: function($$v) {
                          _vm.$set(_vm.formVM.formData, "installer", $$v)
                        },
                        expression: "formVM.formData.installer"
                      }
                    })
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.formVM.isPageFourVisible,
                    expression: "formVM.isPageFourVisible"
                  }
                ]
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "gds-font-demi gds-font-size-xl gds-space-stack-m"
                  },
                  [_vm._v("Contact Information")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "gds-space-stack-m" },
                  [
                    _c("TextInputWithHtml", {
                      attrs: { config: _vm.formVM.textInputConfigcontactName },
                      model: {
                        value: _vm.formVM.formData.contactName,
                        callback: function($$v) {
                          _vm.$set(_vm.formVM.formData, "contactName", $$v)
                        },
                        expression: "formVM.formData.contactName"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "gds-space-stack-m" },
                  [
                    _c("TextInputWithHtml", {
                      attrs: { config: _vm.formVM.textInputConfigContactPhone },
                      model: {
                        value: _vm.formVM.formData.contactPhone,
                        callback: function($$v) {
                          _vm.$set(_vm.formVM.formData, "contactPhone", $$v)
                        },
                        expression: "formVM.formData.contactPhone"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "gds-space-stack-m" },
                  [
                    _c("TextInputWithHtml", {
                      attrs: { config: _vm.formVM.textInputConfigContactEmail },
                      model: {
                        value: _vm.formVM.formData.contactEmail,
                        callback: function($$v) {
                          _vm.$set(_vm.formVM.formData, "contactEmail", $$v)
                        },
                        expression: "formVM.formData.contactEmail"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "gds-space-stack-m" },
                  [
                    _c("CheckboxInputGroup", {
                      attrs: {
                        config:
                          _vm.formVM.checkboxGroupConfigHomeBatteryInterests
                      },
                      model: {
                        value: _vm.formVM.formData.batteryInterests,
                        callback: function($$v) {
                          _vm.$set(_vm.formVM.formData, "batteryInterests", $$v)
                        },
                        expression: "formVM.formData.batteryInterests"
                      }
                    })
                  ],
                  1
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.formVM.isPageFiveVisible,
                    expression: "formVM.isPageFiveVisible"
                  }
                ]
              },
              [
                _vm.formVM.reviewAndSubmitConfig
                  ? _c("ReviewAndSubmit", {
                      attrs: { config: _vm.formVM.reviewAndSubmitConfig }
                    })
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "buttons-container" },
        [
          _vm.formVM.deadEndBrandAndModel
            ? _c("ActionButton", {
                attrs: {
                  text: "Close",
                  "button-clicked": _vm.onCloseButtonClicked
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.formVM.isNextButtonVisible
            ? _c("ActionButton", {
                attrs: {
                  text: "Next",
                  disabled: _vm.formVM.isNextButtonDisabled,
                  "button-clicked": _vm.onNextButtonClicked
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.formVM.isSubmitButtonVisible
            ? _c("ActionButton", {
                attrs: {
                  text: "Submit",
                  loading: _vm.formVM.pendingSave,
                  "button-clicked": _vm.onSubmitButtonClicked
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }