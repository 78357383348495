var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "devices-max-width-my-account" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "gds-font-size-xl gds-space-stack-l" }, [
      _vm._v(
        "\n    Great! It’s time to pick an installer to handle the installation of your " +
          _vm._s(_vm.deviceType) +
          " in " +
          _vm._s(_vm.location) +
          ".\n  "
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "gds-space-stack-l" }, [
      _vm._v(
        "\n    Your installer will answer any questions and provide a quote for your " +
          _vm._s(_vm.deviceType) +
          " installation.\n    The installer will be your main contact for the installation of the system in your home, and will facilitate the connection with GMP and the grid.\n  "
      )
    ]),
    _vm._v(" "),
    _vm._m(1)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "gds-flex-container gds-space-stack-l devices-background-off-white devices-border-radius"
      },
      [
        _c("img", {
          staticClass: "gds-space-inset-l",
          attrs: {
            src:
              "/wp-content/themes/gmptwentynineteen/assets/images/devices/illustration-installer-van.svg",
            alt: "Blue van with tools icon"
          }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { attrs: { href: "#" } }, [
      _c("span", { staticClass: "devices-color-black" }, [
        _vm._v("Common questions about installers")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }