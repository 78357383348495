import GMPAPI from "../../services/gmpapi";
import DataStore from "../../store";
import { DumpError } from "../../utilities";
import { UsageDetailRouterInit } from "./usage/UsageDetailRouter.js";

import CustomReport from "./billingpayment/CustomReport.vue"
import SubpageDashboard from "./accountdashboard/SubpageDashboard.vue";
import SubpageBillingPayment from "./billingpayment/SubpageBillingPayment.vue";
import SubpageUsage from "./usage/SubpageUsage.vue";
import SubpageAccountSettings from "./accountsettings/SubpageAccountSettings.vue";
import SubpageNetMetering from "./netmetering/SubpageNetMetering.vue";

import SubpageNotifications from "./notifications/SubpageNotifications.vue";
import SubpageNotificationOverview from "./notifications/SubpageNotificationOverview.vue";
import SubpageNotificationDevices from "./notifications/SubpageNotificationDevices.vue";
import SubpageNotificationContacts from "./notifications/SubpageNotificationContacts.vue";
import SubpageNotificationUsageAlert from "./notifications/SubpageNotificationUsageAlert.vue";

import SubpageUsageLegacy from "./usage-legacy/SubpageUsageLegacy.vue";
import SubpageOverview from "./billingpayment/SubpageOverview.vue";
import SubpageReport from "./billingpayment/SubpageReport.vue";
import SubpagePreferences from "./billingpayment/SubpagePreferences.vue";
import SubpageSavedPayments from "./billingpayment/SubpageSavedPayments.vue";

import PageUsageUnavailable from "./usage/PageUsageUnavailable.vue";

import SubpageDevices from "./devices/SubpageDevices.vue";

import DevicesOverview from './devices/devicesoverview/DevicesOverview.vue';
import AddDevice from './devices/adddevice/AddDevice.vue';
import DeviceDetail from './devices/devicedetail/DeviceDetail.vue';
import AddDeviceStart from './devices/adddevice/adddevicestart/AddDeviceStart.vue';
import AddDeviceByod from './devices/adddevice/adddevicebyod/AddDeviceByod.vue';
import AddDeviceNew from './devices/adddevice/adddevicenew/AddDeviceNew.vue';

import TempAssets from "./temp-assets/TempAssets.vue";

import DevicesServiceRequest from './devices/devicesservicerequest/DevicesServiceRequest.vue';

export const MyAccountRouterOptions = {
  mode: "history",
  base: "/account",
  // If passed a hash, scroll to the hash. Otherwise, scroll to the top.
  scrollBehavior: function (to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: [
    { path: "/view-report", name: "viewReport", component: CustomReport, props: true },
    { path: "/", component: SubpageDashboard },
    {
      path: "/billing", component: SubpageBillingPayment,
      children: [
        { path: "/", component: SubpageOverview },
        { path: "/report", component: SubpageReport },
        { path: "/preferences", component: SubpagePreferences },
        { path: "/saved-payments", component: SubpageSavedPayments },
      ]
    },
    { path: "/netmetering", component: SubpageNetMetering },
   {
      path: "/notifications", component: SubpageNotifications,
      children: [
        { path: "", component: SubpageNotificationOverview },
        { path: "contacts", component: SubpageNotificationContacts },
        { path: "devices", component: SubpageNotificationDevices }
      ]
    },
    { path: "/configure/usage-alert", component: SubpageNotificationUsageAlert },

    { path: "/usage-new", component: SubpageUsage, beforeEnter: (to, from, next) => {

      const dataStoreState = DataStore.state;

      if (dataStoreState.user
        && dataStoreState.user.currentAccount
        && dataStoreState.user.currentAccount.resilientHome) {
          next('/usage-unavailable');
      }

      if(DataStore.getters.getTestDriveUsage) {
       next();
      } else {
        next('/usage');
      }
    } },
    { path: "/usage", component: SubpageUsageLegacy, beforeEnter: (to, from, next) => {
      if(DataStore.getters.getTestDriveUsage) {
        next('/usage-new');
      } else {
       next();
      }
    }},
    {
      path: "/devices", component: SubpageDevices,
      children: [
        { path: "", component: DevicesOverview },
        { path: "service-request", component: DevicesServiceRequest },
        {
          path: "add-device", component: AddDevice,
          children: [
            { path: "", component: AddDeviceStart },
            { path: "byod", component: AddDeviceByod },
            { path: "new", component: AddDeviceNew }
          ]
        },
        {
          path: ":id",
          name: "DeviceDetail",
          component: DeviceDetail,
          props: (route) => ({ device: route.params.device || null }),
        }
      ]
    },
    { path: "/temp-assets", component: TempAssets, beforeEnter: (to, from, next) => {
      next();
    }},
    { path: "/settings", component: SubpageAccountSettings },
    { path: "/usage-unavailable", component: PageUsageUnavailable },
    { path: "*", redirect: "/" },
  ],
};

let knownLoggedIn = false;

export function MyAccountRouterInit(router) {
  // Enforce required login before showing my account
  router.beforeEach(async (to, from, next) => {
    if (to.path == '/usage/detail') {
      UsageDetailRouterInit();
      next();
    } else if (knownLoggedIn) {
      next();
    } else {
      try {
        DataStore.commit("setRouteLoading", true);
        await GMPAPI.GetCurrentUser();
        // We have auth, but may not have user info
        if (!DataStore.state.user.userinfo) {
          try {
            await DataStore.dispatch("AttemptCookieLogin");
            // Set this, so we don't try to verify login on every route change
            knownLoggedIn = true;
            next();
          } catch (err) {
            window.location.href = "/transit-login?redirect=" + encodeURIComponent(window.location.pathname + window.location.hash + window.location.search);
          }
        } else {
          // Set this, so we don't try to verify login on every route change
          knownLoggedIn = true;
          next();
        }
      } catch (err) {
        if (!(err.response && err.response.status === 401)) {
          DumpError("Account routing error", err);
        }
        window.location.href = "/transit-login?redirect=" + encodeURIComponent(window.location.pathname + window.location.hash + window.location.search);
      } finally {
        DataStore.commit("setRouteLoading", false);
      }
    }
  });
}