<template>
  <div>
    <BackButton v-if="formVM.pageNumber === 1" :route="'/devices/add-device'" />
    <BackButton v-if="formVM.pageNumber > 1 && !formVM.deadEndBrandAndModel" :on-link-clicked="onBackButtonClicked" />
    <div class="form-container gds-space-stack-l">
      <form @submit.prevent="submitForm">
        <FormHeader :config="formVM.headerConfig" />
        <!-- START page 1 -->
        <div v-show="formVM.isPageOneVisible">
          <RadioGroupDeviceType
            v-model="formVM.formData.deviceType"
            :config="formVM.radioButtonConfigDeviceType"
          />
          <RadioGroupFormattedHtml
            v-if="formVM.formData.deviceType"
            v-model="formVM.formData.installationLocation"
            :config="formVM.radioButtonConfigInstallationLocation"
          />
          <RadioGroupFormattedHtml
            v-if="formVM.formData.deviceType"
            v-model="formVM.formData.installationHasSolar"
            :config="formVM.radioButtonConfigInstallationHasSolar"
          />
        </div>
        <!-- END page 1 -->
        <!-- START page 2 -->
        <div v-show="formVM.isPageTwoVisible">
          <DevicesInstallerStart :location="formVM.installationLocation" :device-type="formVM.selectedDeviceTypeLabel" />
        </div>
        <!-- END page 2 -->
        <!-- START page 3 -->
        <div v-show="formVM.isPageThreeVisible" class="gds-flex-container gds-flex-container--left gds-flex-container--stretch gds-flex-container--wrap devices-row-gap">
          <RadioGroupFormattedHtml
            v-if="formVM.formData.deviceType"
            v-model="formVM.formData.installer"
            :config="formVM.radioButtonConfigInstaller"
          />
        </div>
        <!-- END page 3 -->
        <!-- START page 4 -->
        <div v-show="formVM.isPageFourVisible">
          <div class="gds-font-demi gds-font-size-xl gds-space-stack-m">Contact Information</div>
          <div class="gds-space-stack-m">
            <TextInputWithHtml v-model="formVM.formData.contactName" :config="formVM.textInputConfigcontactName" />
          </div>
          <div class="gds-space-stack-m">
            <TextInputWithHtml v-model="formVM.formData.contactPhone" :config="formVM.textInputConfigContactPhone" />
          </div>
          <div class="gds-space-stack-m">
            <TextInputWithHtml v-model="formVM.formData.contactEmail" :config="formVM.textInputConfigContactEmail" />
          </div>
          <div class="gds-space-stack-m">
            <CheckboxInputGroup
              v-model="formVM.formData.batteryInterests"
              :config="formVM.checkboxGroupConfigHomeBatteryInterests"
            />
          </div>
        </div>
        <!-- END page 4 -->
        <!-- START page 5 -->
        <div v-show="formVM.isPageFiveVisible">
          <ReviewAndSubmit v-if="formVM.reviewAndSubmitConfig" :config="formVM.reviewAndSubmitConfig" />
        </div>
        <!-- END page 5 -->
      </form>
    </div>
    <div class="buttons-container">
      <ActionButton
        v-if="formVM.deadEndBrandAndModel"
        text="Close"
        :button-clicked="onCloseButtonClicked"
      />
      <ActionButton
        v-if="formVM.isNextButtonVisible"
        text="Next"
        :disabled="formVM.isNextButtonDisabled"
        :button-clicked="onNextButtonClicked"
      />
      <ActionButton
        v-if="formVM.isSubmitButtonVisible"
        text="Submit"
        :loading="formVM.pendingSave"
        :button-clicked="onSubmitButtonClicked"
      />
    </div>
  </div>
</template>

<script>
import AddNewViewModel from './AddNewViewModel';
import BackButton from '../../shared/components/formcomponents/BackButton.vue';
import FormHeader from '../../shared/components/formcomponents/FormHeader.vue';
import ActionButton from '../../shared/components/formcomponents/ActionButton.vue';
import DevicesInstallerStart from '../../shared/components/DevicesInstallerStart.vue';
import ReviewAndSubmit from '../../shared/components/formcomponents/ReviewAndSubmit.vue';
import TextInputWithHtml from '../../shared/components/formcomponents/TextInputWithHtml.vue';
import CheckboxInputGroup from '../../shared/components/formcomponents/CheckboxInputGroup.vue';
import RadioGroupDeviceType from '../../shared/components/formcomponents/RadioGroupDeviceType.vue';
import RadioGroupFormattedHtml from '../../shared/components/formcomponents/RadioGroupFormattedHtml.vue';
import UtilitiesMixin from '../../../../mixins/UtilitiesMixin';

const DEVICES_PATH = '/devices';

export default {
  name: 'AddDeviceNew',
  components: {
    BackButton,
    FormHeader,
    ActionButton,
    ReviewAndSubmit,
    TextInputWithHtml,
    CheckboxInputGroup,
    RadioGroupDeviceType,
    DevicesInstallerStart,
    RadioGroupFormattedHtml,
  },
  mixins: [
    UtilitiesMixin,
  ],
  data() {
    return {
      formVM: new AddNewViewModel(),
    };
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
  },
  watch: {
    currentAccount() {
      this.$router.push(DEVICES_PATH);
    },
    'formVM.formData': {
      handler(newValue) {
        this.formVM.formDataWatchHandler(newValue);
      },
      deep: true,
    },
    'formVM.pageNumber': {
      handler(newValue, oldValue) {
        this.formVM.pageNumberWatchHandler(newValue, oldValue, this.currentAccount);
      },
      immediate: true,
    },
  },
  mounted() {
    this.formVM.updatePageHeader();
    this.formVM.updateReviewAndSubmitConfig(this.currentAccount);
    this.formVM.updatePrimaryResidenceHtml(this.currentAccount);
  },
  methods: {
    onSubmitButtonClicked() {
      this.formVM.onSubmitButtonClicked();
      setTimeout(() => { this.$router.push(DEVICES_PATH); }, 2500);
    },
    onBackButtonClicked() {
      this.formVM.onBackButtonClicked();
    },
    onNextButtonClicked() {
      this.formVM.onNextButtonClicked();
      this.scrollToTop();
    },
  },
};
</script>
