<template>
  <div class="gds-space-stack-l">
    <h4 v-if="config.title">{{ config.title }}</h4>
    <section class="gds-flex-container gds-flex-container--left gds-flex-container--stretch gds-flex-container--wrap devices-column-gap devices-row-gap">
      <label
        v-for="(option, index) in config.options"
        :key="index"
      >
        <input
          v-model="selectedValue"
          type="radio"
          :value="option.value"
          hidden
        >
        <img
          class="devices-border-radius devices-set-width-152"
          :class="{ 'devices-outline-indigo' : selectedValue === option.value }"
          :src="option.imageURL"
          :alt="option.alt"
        >
      </label>
    </section>
  </div>
</template>

<script>
export default {
  name: 'RadioGroupAsImages',
  props: {
    config: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      selectedValue: this.config.selectedValue || undefined,
    };
  },
  watch: {
    selectedValue: {
      handler(newVal) {
        this.$emit('input', newVal);
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
</style>
