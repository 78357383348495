var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.config.title
      ? _c(
          "div",
          { staticClass: "gds-font-demi gds-font-size-xl gds-space-stack-m" },
          [_vm._v(_vm._s(_vm.config.title))]
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "gds-flex-container gds-flex-container--column gds-flex-container--stretch devices-row-gap devices-max-width-300"
      },
      _vm._l(_vm.config.checkboxes, function(checkbox, index) {
        return _c(
          "label",
          {
            key: index,
            staticClass:
              "gds-flex-container gds-flex-container--left gds-font-demi gds-font-size-m devices-border-grey devices-padding-m",
            attrs: { for: "checkbox-" + index }
          },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.localValues[index],
                  expression: "localValues[index]"
                }
              ],
              staticClass: "gds-checkbox gds-secondary-checkbox",
              attrs: { id: "checkbox-" + index, type: "checkbox" },
              domProps: {
                value: checkbox.value,
                checked: Array.isArray(_vm.localValues[index])
                  ? _vm._i(_vm.localValues[index], checkbox.value) > -1
                  : _vm.localValues[index]
              },
              on: {
                change: [
                  function($event) {
                    var $$a = _vm.localValues[index],
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = checkbox.value,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(_vm.localValues, index, $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.localValues,
                            index,
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.localValues, index, $$c)
                    }
                  },
                  _vm.emitUpdatedValues
                ]
              }
            }),
            _vm._v("\n      " + _vm._s(checkbox.label) + "\n    ")
          ]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }