import { FORM_TYPES } from '../../constants';
import formConfigurations from './FormConfigurationsByod.json';
import AddDeviceFormViewModel from '../shared/AddDeviceFormViewModel';

export default class ByodViewModel extends AddDeviceFormViewModel {
  constructor() {
    super();
    this.pageNumberLast = 5;
    this.currentAccount = {};

    Object.assign(this, formConfigurations);

    this.constants = {
      BATTERY_SHARE_PERCENTAGE: 'batterySharePercentage',
    };

    this.selectedDevice = undefined;
    this.deviceTypeLastSelected = undefined;
    this.lastBatterySharePercentage = undefined;

    this.evChargerBrandLastSelected = undefined;

    this.formData = {
      deviceType: undefined,
      vehicleYear: undefined,
      evChargerBrand: undefined,
      estimatedIncentive: '$3,825',
      vehicleMakeAndModel: undefined,
      chargerSerialNumber: undefined,
      preferredEnrollment: undefined,
      batterySerialNumber: undefined,
      totalBatteryCapacity: undefined,
      installationLocation: undefined,
      installationHasSolar: undefined,
      batteryBrandAndModel: undefined,
      totalEnrolledCapacity: undefined,
      batterySharePercentage: undefined,
      termsAndConditionsConfirmed: undefined,
    };

    this.headerConfig = { ...formConfigurations.headerConfigDefault };

    this.deadEndBrandAndModels = formConfigurations.deadEndBrandAndModels;

    this.primaryResidenceHtml = '';
    this.reviewAndSubmitConfig = { formData: {}, selectedDevice: {} };
  }

  get isBatteryCapacityFieldVisible() {
    return this.formData.batteryBrandAndModel && !this.deadEndBrandAndModel;
  }

  get isBatterySerialNumberVisible() {
    return this.formData.totalBatteryCapacity && !this.deadEndBrandAndModel;
  }

  get batterySelectedOrUnknownDeviceType() {
    return this.batterySelected || this.unknownDeviceType;
  }

  get isSubmitButtonVisible() {
    return this.pageNumber === this.pageNumberLast && !this.deadEndBrandAndModel;
  }

  get isNextButtonVisible() {
    return this.pageNumber < this.pageNumberLast && !this.deadEndBrandAndModel;
  }

  get deadEndBrandAndModel() {
    return this.deadEndBrandAndModels && this.deadEndBrandAndModels.find(
      (model) => model.value === this.formData.batteryBrandAndModel,
    );
  }

  get evChargerSelected() {
    return this.formData.deviceType === 'ev-charger';
  }

  get batterySelected() {
    return this.formData.deviceType === 'home-battery';
  }

  get somethingElseSelected() {
    return this.formData.deviceType === 'something-else';
  }

  get notSureElseSelected() {
    return this.formData.deviceType === 'not-sure';
  }

  get unknownDeviceType() {
    return this.notSureElseSelected || this.somethingElseSelected;
  }

  get isNextButtonDisabled() {
    let pageThreeInvalid = true;
    const data = this.formData;
    const installationLocationDefined = ByodViewModel.isDefined(data.installationLocation);
    switch (this.pageNumber) {
    case 1:
      if (this.evChargerSelected) {
        return !ByodViewModel.isDefined(data.evChargerBrand) || !installationLocationDefined;
      }

      return !ByodViewModel.isDefined(data.installationHasSolar) || !installationLocationDefined;
    case 2:
      if (this.evChargerSelected) {
        return !ByodViewModel.isDefined(data.vehicleYear) || !ByodViewModel.isDefined(data.vehicleMakeAndModel) || !ByodViewModel.stringIsNotEmpty(data.chargerSerialNumber);
      }

      return !ByodViewModel.isDefined(data.batteryBrandAndModel) || !ByodViewModel.isDefined(data.totalBatteryCapacity) || !ByodViewModel.stringIsNotEmpty(data.batterySerialNumber);
    case 3:
      if (this.batterySelected && ByodViewModel.isDefined(data.preferredEnrollment)) {
        if (data.preferredEnrollment === 'self-powering') {
          pageThreeInvalid = false;
        }

        if (data.preferredEnrollment === 'battery-share'
          && ByodViewModel.isDefined(data.batterySharePercentage)
          && ByodViewModel.isNotNull(data.batterySharePercentage)) {
          pageThreeInvalid = false;
        }
      }

      if (this.evChargerSelected) {
        const { discountChargingRate } = data;
        pageThreeInvalid = !ByodViewModel.isDefined(discountChargingRate) || !ByodViewModel.isNotNull(discountChargingRate);
      }

      return pageThreeInvalid;
    case 4:
      return !data.termsAndConditionsConfirmed;
    default:
      return false;
    }
  }

  static isDefined(val) {
    return typeof val !== 'undefined';
  }

  static getMatchingObjectArray(objectsArray, propName, propValue) {
    return objectsArray.find((object) => object[propName] === propValue);
  }

  submitForm() {
    console.dir(this.formData);
  }

  updateReviewAndSubmitConfig() {
    this.reviewAndSubmitConfig = this.getReviewAndSubmitConfig();
  }

  onOptionSelectedBatteryBrandAndModel(batteryBrandAndModel) {
    this.formData.batteryBrandAndModel = batteryBrandAndModel;
    this.setSelectedDevice('value', batteryBrandAndModel, this.selectConfigBatteryBrand.options);
  }

  onOptionSelectedTotalBatteryCapacity(batteryCapacity) {
    this.formData.totalBatteryCapacity = batteryCapacity;
  }

  onOptionSelectedVehicleYear(vehicleYear) {
    this.formData.vehicleYear = vehicleYear;
  }

  onOptionSelectedVehicleMakeAndModel(vehicleMakeAndModel) {
    this.formData.vehicleMakeAndModel = vehicleMakeAndModel;
  }

  setSerialNumber() {
    if (ByodViewModel.isDefined(this.formData.chargerSerialNumber)) {
      this.selectedDevice.deviceId = this.formData.chargerSerialNumber;
    }

    if (ByodViewModel.isDefined(this.formData.batterySerialNumber)) {
      this.selectedDevice.deviceId = this.formData.batterySerialNumber;
    }
  }

  setSelectedDevice(key, value, options) {
    this.selectedDevice = ByodViewModel.getMatchingArrayObject(key, value, options);
    this.selectedDevice.deviceName = this.selectedDevice.label;
    this.setSerialNumber();
  }

  getReviewAndSubmitConfig() {
    return {
      formData: this.formData,
      formType: FORM_TYPES.ADD_DEVICE_BYOD,
      selectedDevice: this.selectedDevice || {},
      accountNumber: this.currentAccount.accountNumber,
      primaryResidenceHtml: this.primaryResidenceHtml,
      reviewAndSubmitMessage: this.reviewAndSubmitMessage,
    };
  }

  pageNumberWatchHandler(newValue, oldValue) {
    if (newValue !== oldValue) {
      this.updatePageHeader();
      if (newValue === this.pageNumberLast) {
        this.updateReviewAndSubmitConfig();
      }
      if (this.isPageFourVisible) {
        this.formData.termsAndConditionsConfirmed = undefined;
      }
    }
  }

  updatePageHeader() {
    const { selectedDeviceType } = this;
    const defaultDeviceType = 'home battery';
    const currentDeviceType = selectedDeviceType ? selectedDeviceType.text : defaultDeviceType;

    const config = this.headerConfig;
    switch (this.pageNumber) {
    case 2:
      if (this.evChargerSelected) {
        this.headerConfig = { ...this.headerConfigDefaultEvCharger };
        break;
      }
      config.header = `Connect a ${currentDeviceType}`;
      config.summary = 'Let’s gather some more information about your device.';
      break;
    case 3:
      if (this.evChargerSelected) {
        this.headerConfig = { ...this.headerConfigDefaultEvCharger };
        break;
      }
      config.header = `Connect a ${currentDeviceType}`;
      config.summary = `Decide how your ${currentDeviceType} will participate during peak events.`;
      break;
    case 4:
      config.header = 'Terms & Conditions';
      config.summary = '';
      break;
    case 5:
      config.header = 'Review and Submit';
      config.summary = `${currentDeviceType} enrollment info`;
      break;
    default:
      this.headerConfig = { ...formConfigurations.headerConfigDefault };
      break;
    }
  }

  formDataWatchHandler(newValue) {
    Object.keys(newValue).forEach((key) => {
      const data = this.formData;

      if (key === 'batterySerialNumber' && newValue[key]) {
        this.setSerialNumber();
      }

      if (key === 'chargerSerialNumber' && newValue[key]) {
        this.setSerialNumber();
      }

      if (key === 'evChargerBrand' && newValue[key]) {
        if (this.evChargerBrandLastSelected !== newValue[key]) {
          this.selectedDevice = undefined;
          data.chargerSerialNumber = undefined;
          this.TextInputConfigChargerSerialNumber.value = undefined;
          this.TextInputConfigChargerSerialNumber = ByodViewModel.copyObject(this.TextInputConfigChargerSerialNumber);
        }

        this.evChargerBrandLastSelected = newValue[key];

        this.setSelectedDevice('value', newValue[key], this.radioButtonConfigEvChargerBrand.options);
      }

      if (key === 'deviceType' && (newValue[key] !== this.deviceTypeLastSelected)) {
        this.selectedDeviceType = ByodViewModel.getMatchingObjectArray(this.deviceTypeLabels, 'value', newValue[key]);

        if (this.selectedDevice && this.selectedDevice.deviceId !== newValue[key]) {
          this.selectedDevice.deviceId = undefined;
        }

        this.selectedDevice = {};
        data.evChargerBrand = undefined;
        data.chargerSerialNumber = undefined;
        data.batterySerialNumber = undefined;
        data.installationLocation = undefined;
        data.installationHasSolar = undefined;

        this.radioButtonConfigInstallationHasSolar.selectedValue = undefined;
        this.radioButtonConfigInstallationHasSolar = ByodViewModel.copyObject(this.radioButtonConfigInstallationHasSolar);

        this.radioButtonConfigInstallationLocation.selectedValue = undefined;
        this.radioButtonConfigInstallationLocation = ByodViewModel.copyObject(this.radioButtonConfigInstallationLocation);
      }

      this.deviceTypeLastSelected = newValue.deviceType;

      if (key === 'preferredEnrollment') {
        if (newValue[key] === 'self-powering') {
          data.totalEnrolledCapacity = null;
          data[this.constants.BATTERY_SHARE_PERCENTAGE] = null;
        } else if (newValue[key] === 'battery-share') {
          data[this.constants.BATTERY_SHARE_PERCENTAGE] = this.lastBatterySharePercentage;
        }
      }
    });

    this.updateReviewAndSubmitConfig();
  }
}
