import { render, staticRenderFns } from "./WidgetWelcomeMessage.vue?vue&type=template&id=80b29b2a&scoped=true&"
import script from "./WidgetWelcomeMessage.vue?vue&type=script&lang=js&"
export * from "./WidgetWelcomeMessage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "80b29b2a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/bamboo-agent-home/xml-data/build-dir/WWW-WEBQA-JOB1/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('80b29b2a')) {
      api.createRecord('80b29b2a', component.options)
    } else {
      api.reload('80b29b2a', component.options)
    }
    module.hot.accept("./WidgetWelcomeMessage.vue?vue&type=template&id=80b29b2a&scoped=true&", function () {
      api.rerender('80b29b2a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/src/components/WidgetWelcomeMessage.vue"
export default component.exports