var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "gds-space-stack-l" }, [
    _vm.config.title ? _c("h4", [_vm._v(_vm._s(_vm.config.title))]) : _vm._e(),
    _vm._v(" "),
    _c(
      "section",
      {
        staticClass:
          "gds-flex-container gds-flex-container--left gds-flex-container--stretch gds-flex-container--wrap devices-column-gap devices-row-gap"
      },
      _vm._l(_vm.config.options, function(option, index) {
        return _c("label", { key: index }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.selectedValue,
                expression: "selectedValue"
              }
            ],
            attrs: { type: "radio", hidden: "" },
            domProps: {
              value: option.value,
              checked: _vm._q(_vm.selectedValue, option.value)
            },
            on: {
              change: function($event) {
                _vm.selectedValue = option.value
              }
            }
          }),
          _vm._v(" "),
          _c("img", {
            staticClass: "devices-border-radius devices-set-width-152",
            class: {
              "devices-outline-indigo": _vm.selectedValue === option.value
            },
            attrs: { src: option.imageURL, alt: option.alt }
          })
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }