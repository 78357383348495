<template>
  <div class="make-payment-container">
    <div id="top-target" class="eicproduct-success-fail">
      <div class="eicproduct-success__intro-image">
        <img class="my-account__payment-plan-icon" src="/wp-content/themes/gmptwentynineteen/assets/images/home-ev-charger.png" alt="successful payment" />
      </div>

      <h2 class="gds-display-2 gds-align--text-center gds-space-stack-ml">Thank you!</h2>
      <div v-if="!isRestrictedRebateUser" class="gds-font-size-l gds-space-stack-l successBody">We have your rebate and will email you!</div>
      <div v-if="isRestrictedRebateUser" class="gds-font-size-l gds-space-stack-l successBody">The customer's rebate has been successfully submitted!</div>
      <div class="rebate-success__button-wrap gds-align--text-center">
        <button v-if="isRestrictedRebateUser" @click="onSubmitAnotherHandler()" type="button" class="eicproduct-success__button gds-button" style="margin-right: 1px;">Submit Another Rebate</button>
        <button @click="onDoneHandler()" type="button" class="eicproduct-done__button gds-button">Done</button>
      </div>
    </div>
  </div>
</template>

<script>
import { GMPPhoneNumber } from "../../../environment";
import { ReturnToSender } from "../../../utilities";
import UtilitiesMixin from '../../mixins/UtilitiesMixin';

export default {
  name: "EVRebateComplete",
  mixins: [
    UtilitiesMixin,
  ],
  data() {
    return {
      ReturnToSender,
      GMPPhoneNumber,
    };
  },
  computed: {
    isRestrictedRebateUser() {
      return !!this.$store.state?.user?.userinfo?.groups?.find(item => item.name === 'RESTRICTED_REBATE_USER');
    },
  },
  async mounted() {
    this.scrollToElement('ev-rebate-header');
  },
  methods: {
    onDoneHandler() {
      if (!this.isRestrictedRebateUser) {
        ReturnToSender('/');
      } else {
        this.logout();
      }
    },
    onSubmitAnotherHandler() {
        this.$router.replace("/electric-vehicle-rebate");
    },
    async logout() {
      try {
        await this.$store.dispatch("LogOut");
        this.$router.replace("/login");
      } catch (err) {
        DumpError("Logout error", err);
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
