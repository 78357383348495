<template>
  <div class="gmp-devices-page__start">
    <div class="gds-space-stack-m">
      <BackButton v-if="isAddDevicePath" :route="'/devices'" />
    </div>
    <div class="gds-with-sidebar gds-with-sidebar--reverse">
      <section class="not-sidebar">
        <h1 class="my-account__title gds-space-stack-ml">{{ isAddDevicePath ? "Add a Device" : "Devices" }}</h1>
        <div class="gds-space-stack-l">Connect a Home Battery or EV Charger with GMP. Save energy and money without disrupting your lifestyle. Choose from an option below:</div>
        <section class="devices-start-link-container gds-space-stack-l">
          <router-link
            v-if="routerLinkShouldShowByod"
            class="gds-button gds-secondary gds-compact devices-thin-button devices-thin-button gds-space-stack-m"
            to="/devices/add-device/byod/"
          >
            <img
              class="gds-icon"
              src="/wp-content/themes/gmptwentynineteen/assets/images/devices/icon-devices-house.svg"
              alt="Blue house icon"
            >
            <span class="">Connect a device you own</span>
            <svg class="gds-icon gds-text-grey">
              <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-chevron-right" />
            </svg>
          </router-link>
          <a
            v-if="hyperlinkShouldShowByod"
            :href="txtAddDeviceBYOD"
            target="_blank"
            class="gds-button gds-secondary gds-compact devices-thin-button gds-space-stack-m"
          >
            <img
              class="gds-icon"
              src="/wp-content/themes/gmptwentynineteen/assets/images/devices/icon-devices-house.svg"
              alt="Blue house icon"
            >
            <span>Connect a device you own</span>
            <svg class="gds-icon gds-text-grey">
              <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-chevron-right" />
            </svg>
          </a>
          <router-link
            v-if="routerLinkShouldShowAddNew"
            class="gds-button gds-secondary gds-compact devices-thin-button gds-space-stack-m"
            to="/devices/add-device/new"
          >
            <img
              class="gds-icon"
              src="/wp-content/themes/gmptwentynineteen/assets/images/devices/icon-devices-plus-in-circle.svg"
              alt="White plus sign in blue circle icon"
            >
            <span>Get a new device installed</span>
            <svg class="gds-icon gds-text-grey">
              <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-chevron-right" />
            </svg>
          </router-link>
          <a
            v-if="hyperlinkShouldShowAddNew"
            :href="txtAddDeviceNew"
            target="_blank"
            class="gds-button gds-secondary gds-compact devices-thin-button gds-space-stack-m"
          >
            <img
              class="gds-icon"
              src="/wp-content/themes/gmptwentynineteen/assets/images/devices/icon-devices-plus-in-circle.svg"
              alt="White plus sign in blue circle icon"
            >
            <span>Get a new device installed</span>
            <svg class="gds-icon gds-text-grey">
              <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-chevron-right" />
            </svg>
          </a>
        </section>
        <div class="devices-start-small-message">
          <p>Not sure or have questions?</p>
          <a
            :href="txtAskEnergySpecialist"
            target="_blank"
            class="gds-text-grey"
          >Speak with a Home Energy Specialist</a>
        </div>
      </section>
      <div class="sidebar">
      <img
        class="devices-border-radius devices-width-100"
          src="/wp-content/themes/gmptwentynineteen/assets/images/devices/devices-three-in-row.jpg"
          alt="EV Charger and Home Battery System"
        >
      </div>
    </div>
  </div>
</template>

<script>
import BackButton from '../../shared/components/formcomponents/BackButton.vue';
import { isFeatureEnabled, getConfigValue } from '../../../../../services/featureflags';

const ADD_DEVICE_URL_DEFAULT = 'https://greenmountainpower.com/rebates-programs/';
const ASK_ENERGY_SPECIALIST_URL_DEFAULT = 'https://greenmountainpower.com/contact/';

export default {
  name: 'AddDeviceStart',
  components: {
    BackButton,
  },
  data() {
    return {
      ffAddDeviceNew: undefined,
      ffAddDeviceBYOD: undefined,
      txtAddDeviceNew: undefined,
      txtAddDeviceBYOD: undefined,
      featureFlagsChecked: undefined,
      txtAskEnergySpecialist: undefined,
    };
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
    routerLinkShouldShowByod() {
      return this.featureFlagsChecked && this.ffAddDeviceBYOD;
    },
    routerLinkShouldShowAddNew() {
      return this.featureFlagsChecked && this.ffAddDeviceNew;
    },
    hyperlinkShouldShowByod() {
      return this.featureFlagsChecked && !this.ffAddDeviceBYOD;
    },
    hyperlinkShouldShowAddNew() {
      return this.featureFlagsChecked && !this.ffAddDeviceNew;
    },
    isAddDevicePath() {
      const { fullPath } = this.$route;
      const addDevicesPath = '/devices/add-device';
      return fullPath === addDevicesPath || fullPath === `${addDevicesPath}/`;
    },
  },
  watch: {
    currentAccount() {
      this.$router.push('/devices');
    },
  },
  async mounted() {
    await this.checkFeatureFlag();
  },
  methods: {
    async checkFeatureFlag() {
      const { accountNumber } = this.currentAccount;

      this.ffAddDeviceNew = await isFeatureEnabled('FF_AddDeviceNew', false, { accountNumber });
      this.ffAddDeviceBYOD = await isFeatureEnabled('FF_AddDeviceBYOD', false, { accountNumber });

      this.txtAddDeviceNew = await getConfigValue('TXT_AddDeviceNew', ADD_DEVICE_URL_DEFAULT);
      this.txtAddDeviceBYOD = await getConfigValue('TXT_AddDeviceBYOD', ADD_DEVICE_URL_DEFAULT);

      this.txtAskEnergySpecialist = await getConfigValue('TXT_AskEnergySpecialist', ASK_ENERGY_SPECIALIST_URL_DEFAULT);

      this.featureFlagsChecked = true;
    },
  },
};
</script>
