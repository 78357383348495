<template>
  <section class="devices-max-width-my-account">
    <div class="gds-flex-container gds-space-stack-l devices-background-off-white devices-border-radius">
      <img
        class="gds-space-inset-l"
        src="/wp-content/themes/gmptwentynineteen/assets/images/devices/illustration-installer-van.svg"
        alt="Blue van with tools icon"
      >
    </div>
    <div class="gds-font-size-xl gds-space-stack-l">
      Great! It’s time to pick an installer to handle the installation of your {{ deviceType }} in {{ location }}.
    </div>
    <div class="gds-space-stack-l">
      Your installer will answer any questions and provide a quote for your {{ deviceType }} installation.
      The installer will be your main contact for the installation of the system in your home, and will facilitate the connection with GMP and the grid.
    </div>
    <a href="#">
      <span class="devices-color-black">Common questions about installers</span>
    </a>
  </section>
</template>

<script>
export default {
  name: 'DevicesInstallerStart',
  props: {
    location: {
      type: String,
      required: true,
      default: '',
    },
    deviceType: {
      type: String,
      required: true,
      default: '',
    },
  },
};
</script>

<style scoped>
</style>
